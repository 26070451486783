<template lang="">
  <modal-padrao
    ref="modal-input"
    :max-width="1000"
    :titulo="$t('componentes.input_instrumento.titulo_modal')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="aplicarSelecionados"
  >
    <div class="d-flex align-end">
      <div class="row">
        <input-text
          v-model="filtro.codigoTag"
          :label="$t('componentes.input_instrumento.codigo')"
          class="col-12 col-md-4"
        />
        <input-text
          v-model="filtro.nomeTipoInstrumento"
          :label="$t('componentes.input_instrumento.nome')"
          class="col-12 col-md-4"
        />
        <input-text
          v-model="filtro.faixa"
          :label="$t('componentes.input_instrumento.faixa')"
          class="col-12 col-md-4"
        />
      </div>
      <botao-padrao
        outlined
        color="secondary"
        class="ml-2"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-acoes
      :selecionar-apenas-um="!multiplos"
      @paginar="listar"
    />
  </modal-padrao>
</template>
<script>
import InstrumentoService from '@common/services/cadastros/InstrumentoService';
import _ from 'lodash';
export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      filtro: {
        nome: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: "codigoTag",
            text: this.$t("componentes.input_instrumento.tabela.codigo"),
            sortable: false,
          },
          {
            value: "tipoInstrumento.nome",
            text: this.$t("componentes.input_instrumento.tabela.tipo"),
            sortable: false,
          },
          {
            value: "numeroSerie",
            text: this.$t("componentes.input_instrumento.tabela.numero_serie"),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  watch: {
    value: {
      handler() {
        this.definirSelecionados()
      },
  },
  },
  mounted() {
    this.listar();
  },
  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },
    abrirModal(){
      this.$refs['modal-input'].abrirModal();
      this.listar();
    },
    definirSelecionados(){
      this.selecionados = this.tabela.dados.filter(el => this.value.some(instrumento => instrumento.instrumentoId == el.id));
    },
    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      InstrumentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.definirSelecionados()
        })
    },
    aplicarSelecionados: function () {
      this.$refs['modal-input'].fecharModal();
      this.$emit('associarInstrumentos', this.selecionados);
      },
  },
};
</script>
