export class RegistroInformativoModel {
  constructor({
    id,
    dataOcorrencia = new Date(),
    dataEmisao = new Date(),
    ocorrenciaPadraoId,
    ocorrenciaPadrao,
    nome,
    ocorrencia,
    acaoCorretiva,
    ordemServicoId,
    ordemServico,
    participanteId,
    participanteNome,
    usuarioResponsavelId,
    usuarioResponsavel,
    texto,
    setores = [],
    instrumentos = [],
    codigo,
    sequencia,
  }) {
    this.id = id;
    this.dataEmisao = dataEmisao;
    this.dataOcorrencia = dataOcorrencia;
    this.ocorrenciaPadraoId = ocorrenciaPadraoId;
    this.ocorrenciaPadrao = ocorrenciaPadrao;
    this.nome = nome;
    this.ocorrencia = ocorrencia;
    this.acaoCorretiva = acaoCorretiva;
    this.ordemServicoId = ordemServicoId;
    this.ordemServico = ordemServico;
    this.participanteId = participanteId;
    this.participanteNome = participanteNome;
    this.usuarioResponsavelId = usuarioResponsavelId;
    this.usuarioResponsavel = usuarioResponsavel;
    this.texto = texto;
    this.setores = setores;
    this.instrumentos = instrumentos;
    this.codigo = codigo;
    this.sequencia = sequencia;

    this.instrumentos?.forEach((instrumento) => {
      instrumento.registroOcorrenciaId = instrumento.registroOcorrencia?.id;
    });
  }
}
