<template>
  <div>
    <tabela-padrao
      :key="keyAtualiza"
      v-model="tabela.selecionados"
      :dados="form.setores"
      class="mt-2"
      :colunas="tabela.colunas"
    />

    <setor-modal ref="modal-setor" @confirmar-setor="confirmarNovoSetor" />
  </div>
</template>
<script>
import SetorModal from './modais/SetorModal';
export default {
  components: {
    SetorModal,
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      keyAtualiza: 1,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'nome',
            text: this.$t('modulos.ocorrencia_padrao.formulario.setores.nome'),
            sortable: true,
          },
          {
            value: 'tipoSetorDescricao',
            text: this.$t('modulos.ocorrencia_padrao.formulario.setores.tipo'),
            sortable: true,
          },
          {
            value: 'sigla',
            text: this.$t('modulos.ocorrencia_padrao.formulario.setores.sigla'),
            sortable: true,
          },
        ],
      },
    };
  },
  watch: {
    'tabela.selecionados'() {
      this.form.setores.map((item) => {
        item.flagSelecionado = true;
      });

      let arrayDesmarcado = this.form.setores.filter(
        ({ id: id1 }) =>
          !this.tabela.selecionados.some(({ id: id2 }) => id2 === id1)
      );
      arrayDesmarcado.map((item) => {
        item.flagSelecionado = false;
      });

      this.form.setores.map((itemSetor) => {
        arrayDesmarcado.forEach((itemDesmarcado) => {
          if (itemSetor.id === itemDesmarcado.id)
            itemSetor.flagSelecionado = false;
        });
      });
    },
  },
  mounted() {
    this.form.setores.map((item) => {
      if (item.flagSelecionado) this.tabela.selecionados.push(item);
    });
    this.keyAtualiza++;
  },
  methods: {
    confirmarNovoSetor: function (form) {
      if (form.length) {
        form.map((item) => {
          item.tipoSetorDescricao = item.tipoSetorDescricao
            ? item.tipoSetorDescricao
            : item.tipoSetor.descricao;
          item.setorId = item.id;
          item.id = null;
        });
        this.form.setores = form;
      }
    },
    abrirNovo: function () {
      this.$refs['modal-setor'].abrirModal(this.form.setores);
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.setores.splice(this.form.setores.indexOf(element), 1);
        });
        this.tabela.selecionados = [];
      });
    },
  },
};
</script>
