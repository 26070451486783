<template>
  <div>
    <v-form ref="form" class="row mt-2">
      <input-text
        v-model="form.codigo"
        class="col-12 col-md-4"
        :label="$t('modulos.registro_informativo.formulario.codigo')"
        disabled
        :max="100"
      />
      <input-date
        v-model="form.dataEmisao"
        class="col-12 col-md-4"
        :label="$t('modulos.registro_informativo.formulario.data_emissao')"
        obrigatorio
        disabled
      />
      <input-date
        v-model="form.dataOcorrencia"
        class="col-12 col-md-4"
        :label="$t('modulos.registro_informativo.formulario.data_ocorrencia')"
        obrigatorio
      />
      <input-ocorrencia
        v-model="form.ocorrenciaPadrao"
        class="col-12 col-md-3"
        :label="$t('modulos.registro_informativo.formulario.ocorrencia_padrao')"
        :multiplos="false"
        obrigatorio
      />
      <input-text
        v-model="form.nome"
        obrigatorio
        class="col-12 col-md-9"
        :label="$t('modulos.registro_informativo.formulario.nome')"
      />
      <input-textarea
        v-model="form.ocorrencia"
        obrigatorio
        class="col-12"
        :label="$t('modulos.registro_informativo.formulario.ocorrencia')"
      />
      <input-textarea
        v-model="form.acaoCorretiva"
        obrigatorio
        class="col-12"
        :label="$t('modulos.registro_informativo.formulario.acao_corretiva')"
      />
      <input-ordem-servico
        v-model="form.ordemServico"
        obrigatorio
        class="col-12 col-md-4 text-center"
        :label="$t('modulos.registro_informativo.formulario.ordem_servico')"
        :multiplos="false"
      />
      <input-text
        v-model="form.participanteNome"
        obrigatorio
        disabled
        class="col-12 col-md-8"
        :label="$t('modulos.registro_informativo.formulario.participante')"
      />
      <input-textarea
        v-model="form.texto"
        class="col-12"
        :label="$t('modulos.registro_informativo.formulario.texto')"
      />
      <input-usuarios
        v-model="form.usuarioResponsavel"
        obrigatorio
        class="col-12"
        :label="
          $t('modulos.registro_informativo.formulario.usuario_responsavel')
        "
        :multiplos="false"
      />
    </v-form>
  </div>
</template>
<script>
import OcorrenciaPadraoService from '@common/services/cadastros/OcorrenciaPadraoService';
import OrdemServicoService from '@common/services/cadastros/OrdemServicoService.js';
import { OcorrenciaPadraoModel } from '@common/models/cadastros/OcorrenciaPadraoModel';
import { OrdemServicoModel } from '@common/models/cadastros/OrdemServicoModel.js';
import {
  InputUsuarios,
  InputOcorrencia,
  InputOrdemServico,
} from '@components/inputs';
export default {
  components: {
    InputUsuarios,
    InputOcorrencia,
    InputOrdemServico,
  },
  props: {
    form: { type: Object, default: () => ({}) },
    editado: { type: Boolean, default: false },
  },
  data() {
    return {
      dadosOcorrencia: new OcorrenciaPadraoModel({}),
      dadosOrdemServico: new OcorrenciaPadraoModel({}),
    };
  },
  watch: {
    'form.ocorrenciaPadrao'(valor) {
      if (valor?.value && this.editado) {
        this.form.ocorrenciaPadraoId = valor.value;
        this.buscaDadosOcorrenciaPadrao(valor.value);
      }
    },
    'form.ordemServico'(valor) {
      if (valor?.value && this.editado) {
        this.form.ordemServicoId = valor.value;
        this.buscaDadosOrdemServico(valor.value);
      }
    },
    'form.usuarioResponsavel'(valor) {
      this.form.usuarioResponsavelId = valor?.value;
    },
  },
  methods: {
    buscaDadosOrdemServico(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrdemServicoService.buscar(id)
        .then((res) => {
          this.dadosOrdemServico = new OrdemServicoModel(res.data);
          this.form.participanteNome = res.data?.clienteParticipante?.nome;
          this.form.participanteId = res.data?.clienteParticipante?.id;
          this.form.instrumentos = res.data?.ordensServicosInstrumentos;
          this.form.instrumentos?.map((item) => {
            item.flagSelecionado = true;
            item.id = item.instrumentoId;
          });
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.ocorrencia_padrao.erros.id_invalido...')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscaDadosOcorrenciaPadrao(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OcorrenciaPadraoService.buscar(id)
        .then((res) => {
          this.dadosOcorrencia = new OcorrenciaPadraoModel(res.data);
          this.form.nome = this.dadosOcorrencia.nome;
          this.form.ocorrencia = this.dadosOcorrencia.ocorrencia;
          this.form.acaoCorretiva = this.dadosOcorrencia.acaoCorretiva;
          this.form.setores = this.dadosOcorrencia.setores;

          this.form.setores?.map((item) => {
            item.tipoSetorDescricao = item.tipoSetor;
            item.id = item.setorId;
            item.flagSelecionado = true;
          });
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.ocorrencia_padrao.erros.id_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
