<template lang="">
  <div class="mt-5">
    <tabela-padrao
      :key="keyAtualiza"
      v-model="tabela.selecionados"
      :dados="form.instrumentos"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      sem-paginacao
    >
      <template v-slot:item.registroOcorrencia.descricao="{ item }">
        <div
          class="d-flex justify-space-between"
          style="width: 180px;"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                class="registro-ocorrencia-texto-cortado"
                v-on="on"
              >
                {{ item.item?.registroOcorrencia?.observacao }}
              </span>
            </template>
            <span>{{ item.item?.registroOcorrencia?.observacao }}</span>
          </v-tooltip>
          <v-btn
            class="botao-informativo-ocorrencia-instrumento ml-2"
            color="primary"
            @click.prevent="associarRegistroOcorrencia(item.item)"
          >
            <v-icon size="14">
              $search
            </v-icon>
          </v-btn>
        </div>
      </template>>
    </tabela-padrao>
    <instrumento-modal
      ref="modal-instrumento"
      :value="form.instrumentos"
      @associarInstrumentos="associarInstrumentos"
    />
    <registro-ocorrencia-modal 
      ref="registro-ocorrencia-modal"
      @ok="atualizarTabela"
    />
  </div>
</template>
<script>
import InstrumentoModal from './modais/InstrumentoModal.vue';
import RegistroOcorrenciaModal from './modais/RegistroOcorrenciaModal.vue';
export default {
  components: { InstrumentoModal, RegistroOcorrenciaModal },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      keyAtualiza: 1,
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
            width: '0',
            sortable: true,
          },
          {
            value: 'codigoTag',
            text: this.$t(
              'modulos.ordem_servico.formulario.instrumento.codigo'
            ),
            sortable: true,
            width: '100',
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.ordem_servico.formulario.instrumento.tipo_instrumento'
            ),
            sortable: true,
            width: '200',
          },
          {
            value: 'registroOcorrencia.descricao',
            text: this.$t(
              'modulos.registro_informativo.tabela.registro_ocorrencia'
            ),
            sortable: false,
            width: '200',
          },
          {
            value: '',
            text: '',
            sortable: false,
            width: '300',
          }
        ],
        selecionados: [],
      },
    };
  },
  watch: {
    'tabela.selecionados'() {
      this.form.instrumentos.map((item) => {
        item.flagSelecionado = true;
      });

      let arrayDesmarcado = this.form.instrumentos.filter(
        ({ id: id1 }) =>
          !this.tabela.selecionados.some(({ id: id2 }) => id2 === id1)
      );
      arrayDesmarcado.map((item) => {
        item.flagSelecionado = false;
      });

      this.form.instrumentos.map((itemSetor) => {
        arrayDesmarcado.forEach((itemDesmarcado) => {
          if (itemSetor.id === itemDesmarcado.id)
            itemSetor.flagSelecionado = false;
        });
      });
    },
  },
  mounted() {
    this.form.instrumentos.map((item) => {
      if (item.flagSelecionado) this.tabela.selecionados.push(item);
    });
    this.keyAtualiza++;
  },
  methods: {
    associarDesassociarInstrumento() {
      this.$refs['modal-instrumento'].abrirModal(this.form);
    },
    associarRegistroOcorrencia(instrumento){
      this.$refs['registro-ocorrencia-modal'].abrirModal(instrumento)
    },
    associarInstrumentos(instrumentos) {
      this.form.instrumentos = [];
      instrumentos.forEach((instrumento) => {
        this.form.instrumentos.push({
          ...instrumento,
          instrumentoId: instrumento.id,
        });
      });
    },
    atualizarTabela(){
      this.keyAtualiza++
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.instrumentos.splice(
            this.form.instrumentos.indexOf(element),
            1
          );
        });
        this.tabela.selecionados = [];
      });
    },
  },
};
</script>
<style>
  .botao-informativo-ocorrencia-instrumento {
    text-transform: capitalize;
    padding: 3px !important;
    min-width: 0px !important;
    height: 20px !important;
  }

  .registro-ocorrencia-texto-cortado {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 180px;
  }
</style>
