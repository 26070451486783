<template lang="">
  <modal-padrao
    ref="modal-registro-ocorrencia"
    max-width="70%"
    :titulo="$t('modulos.registro_ocorrencia.associar_registro_ocorrencia')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="associarRegistroOcorrencia"
  >
    <div
      class="d-flex justify-space-between align-end
      "
    >
      <input-text 
        v-model="filtroAplicado.instrumento"
        class="w-90"
        :label="$t('modulos.registro_ocorrencia.formulario.instrumento')"
        @keydown.enter="listarTiposInstrumentos"
      />
      <input-text 
        v-model="filtroAplicado.ocorrenciaPadrao"
        class="w-90 ml-2"
        :label="$t('modulos.registro_ocorrencia.formulario.ocorrencia_padrao')"
        @keydown.enter="listarTiposInstrumentos"
      />
      <input-text 
        v-model="filtroAplicado.observacao"
        class="w-90 ml-2"
        :label="$t('modulos.registro_ocorrencia.formulario.observacao')"
        @keydown.enter="listarTiposInstrumentos"
      />
      <botao-padrao
        color="secundary"
        class="ml-4"
        outlined
        blocked
        @click.prevent="listarRegistros()"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      selecionar-apenas-um
      @paginar="listarRegistros"
    />
  </modal-padrao>
</template>
<script>
import RegistroOcorrenciaService from '@common/services/cadastros/RegistroOcorrenciaService.js'

export default {
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'observacao',
            text: this.$t('modulos.registro_ocorrencia.formulario.observacao'),
            sortable: true,
          },
          {
            value: 'ocorrenciaPadrao.nome',
            text: this.$t('modulos.registro_ocorrencia.formulario.ocorrencia_padrao'),
            sortable: true,
          },
          {
            value: 'instrumento.nome',
            text: this.$t('modulos.registro_ocorrencia.formulario.instrumento'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: {
        nome: ""
      },
      referenciaForm: null
    };
  },
  methods: {
    abrirModal: function (instrumento) {
      this.listarRegistros()
      this.referenciaForm = instrumento
      this.$refs['modal-registro-ocorrencia'].abrirModal();
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      RegistroOcorrenciaService.listar(parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = this.paginaAtual;
          this.tabela.porPagina = this.porPagina;
        }).finally(() => {
          const ocorrencia = this.tabela.dados.find(el => el.id == this.referenciaForm.registroOcorrenciaId)
          this.tabela.selecionados.push(ocorrencia)
        })
    },
    salvarOcorrencia(){
      const { id, observacao } = this.tabela.selecionados[0]
      this.referenciaForm.registroOcorrencia = { id, observacao }
      this.referenciaForm.registroOcorrenciaId = id;
      this.$emit("ok")
      this.$refs['modal-registro-ocorrencia'].fecharModal()
    },
    limparOcorrencia(){
      this.referenciaForm.registroOcorrenciaId = null
      this.referenciaForm.registroOcorrencia = null
      this.$emit("ok")
      this.$refs['modal-registro-ocorrencia'].fecharModal()
    },
    associarRegistroOcorrencia(){
      if(!this.tabela.selecionados[0]) {
        this.limparOcorrencia()
        return;
      }
      this.salvarOcorrencia()
    }
  },
}
</script>
