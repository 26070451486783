<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{
          $t(
            'modulos.registro_informativo.formulario.abas.dados_registro_informativo'
          )
        }}
      </v-tab>
      <v-tab :disabled="!form.ocorrenciaPadraoId">
        {{ $t('modulos.registro_informativo.formulario.abas.setores') }}
      </v-tab>
      <v-tab :disabled="!form.ordemServicoId">
        {{ $t('modulos.registro_informativo.formulario.abas.instrumentos') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-registro-informativo
          ref="dados-registro-informativo"
          :form="form"
          :editado="editado"
        />
      </v-tab-item>
      <v-tab-item>
        <setores :form="form" />
      </v-tab-item>
      <v-tab-item>
        <instrumentos :form="form" />
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import RegistroInformativoService from '@common/services/cadastros/RegistroInformativoService';
import OcorrenciaPadraoService from '@common/services/cadastros/OcorrenciaPadraoService';
import DadosRegistroInformativo from './components/DadosRegistroInformativo';
import Setores from './components/Setores';
import Instrumentos from './components/Instrumentos';
import { RegistroInformativoModel } from '@common/models/cadastros/RegistroInformativoModel';
import helpers from '@common/utils/helpers';
export default {
  components: {
    DadosRegistroInformativo,
    Setores,
    Instrumentos,
  },
  props: {
    id: { type: String, default: '' },
  },
  data() {
    return {
      valido: false,
      validaSetores: true,
      validaInstrumentos: true,
      editado: false,
      indiceAba: 0,
      form: new RegistroInformativoModel({}),
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id)
        return this.$t('modulos.registro_informativo.titulos.editar');
      return this.$t('modulos.registro_informativo.titulos.novo');
    },
  },
  mounted: async function () {
    this.form.usuarioResponsavelId = localStorage.getItem('usuarioId');
    this.form.usuarioResponsavel = {
      text: localStorage.getItem('usuarioNome'),
      value: localStorage.getItem('usuarioId'),
    };
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'RegistroInformativo', 'Editar');
      await this.buscar(this.id);
    } else {
      setTimeout(() => {
        this.editado = true;
      }, 400);
    }
    helpers.redirecionarSemPermissao(this, 'RegistroInformativo', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscaDadosOcorrencia(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OcorrenciaPadraoService.buscar(id)
        .then((res) => {
          this.form.ocorrenciaPadraoId = res.data.id;
          this.form.ocorrenciaPadrao = {
            text: res.data.nome,
            value: res.data.id,
          };
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.ocorrencia_padrao.erros.id_invalido')
          );
        })
        .finally(() => {
          this.valido = true;
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await RegistroInformativoService.buscar(this.id)
        .then(async (res) => {
          if (res.data.ocorrenciaPadraoId)
            await this.buscaDadosOcorrencia(res.data.ocorrenciaPadraoId);
          this.form = new RegistroInformativoModel(res.data);
          setTimeout(() => {
            this.editado = true;
          }, 400);
          this.form.ordemServicoId = res.data.ordemServico.id;
          this.form.ordemServico = {
            text: res.data.ordemServico.nome,
            value: res.data.ordemServico.id,
          };
          this.form.usuarioResponsavel = {
            text: res.data.usuarioResponsavel.nome,
            value: res.data.usuarioResponsavel.id,
          };
          this.form.setores?.map((item) => {
            item.setorId = item.setor.id;
            item.nome = item.setor?.nome;
            // item.posicao = item.setor?.tipoSetor?.posicao;
            item.sigla = item.setor?.sigla;
            item.tipoSetorDescricao = item.setor?.tipoSetor?.descricao;
          });
          this.form.instrumentos?.map((item) => {
            item.instrumentoId = item.instrumento.id;
            item.codigoTag = item.instrumento?.codigoTag;
            item.tipoInstrumento = {
              nome: item.instrumento?.tipoInstrumento?.nome,
            };
            item.numeroSerie = item.instrumento?.numeroSerie;
            item.quantidadeInstrumentoFaixas =
              item.instrumento?.quantidadeInstrumentoFaixas;
          });

          this.form.participanteNome = res.data?.participante?.nome;
          this.form.participanteId = res.data?.participante?.id;
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.registro_informativo.erros.id_invalido')
          );
        })
        .finally(() => {
          this.valido = true;
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (!this.camposObrigatoriosPreenchidos()) return;
      if (!this.validarRegistroInformativo()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    camposObrigatoriosPreenchidos: function () {
      return this.$refs['dados-registro-informativo'].$refs.form.validate();
    },
    validarRegistroInformativo: function () {
      if (this.$refs['dados-registro-informativo']) {
        this.valido =
          this.$refs['dados-registro-informativo'].$refs.form.validate();
        this.indiceAba = 0;
      }
      if (new Date(this.form.dataOcorrencia) > new Date(this.form.dataEmisao)) {
        this.valido = false;
        this.toastAlerta(
          this.$t(
            `modulos.registro_informativo.erros.data_ocorrencia_maior_data_emissao`
          )
        );
      }

      this.validaSetores = true;
      this.form.setores.forEach((item) => {
        if (item.flagSelecionado) {
          this.validaSetores = false;
        }
      });

      if (this.validaSetores) {
        this.valido = false;
        this.indiceAba = 1;
        this.toastAlerta(
          this.$t(`modulos.registro_informativo.erros.setores_selecione`)
        );
      }

      this.validaInstrumentos = true;
      this.form.instrumentos.forEach((item) => {
        if (item.flagSelecionado) {
          this.validaInstrumentos = false;
        }
      });
      if (this.validaInstrumentos) {
        this.valido = false;
        this.indiceAba = 2;
        this.toastAlerta(
          this.$t(`modulos.registro_informativo.erros.instrumentos_selecione`)
        );
      }
      return this.valido;
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RegistroInformativoService.salvar(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.registro_informativo.cadastro_sucesso`)
          );
          this.$router.push({ name: 'registro-informativo' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'registro-informativo' });
      });
    },
  },
};
</script>
